.filthypillow {
  position: relative;
}

.fp-container .active {
  background: lightblue;
  border-radius: 0px;
}

.fp-description { 
  display: none;
  font-weight: 700;
  padding: 5px 0;
}

.fp-errors {
  background: #FF7878;
  color: white;
  padding: 4px 0;
  display: none;
}

.fp-container {
  font-size: 0.9em;
  font-family: sans-serif;
  text-transform: uppercase;
  text-align: center;
  line-height: 1em;
  box-shadow: 2px 2px 2px 2px rgba( 0, 0, 0, 0.4 );
  z-index: 9999;
  position: absolute;
  width: 300px;
  background: #fff;
}

.fp-option {
  border-radius: 4px;
  background: #fff;
  margin: 2px;
  padding: 2px;
  cursor: pointer;
}

.fp-save {
  text-transform: uppercase;
  float: right;
  margin: 4px 4px 0 0;
  display: inline;
}

.fp-clock, .fp-calendar {
  width: 35%;
  padding: 8px 0;
  margin: 6px 5px 5px 5px;
  border-radius: 4px;
  background: white;
  border: 1px solid #c0c0c0;
  display: inline-block;
}

.fp-calendar {
  text-align: left;
}

.fp-clock {
  margin-right: 0;
}

/* Calendar */
.fp-cal-container {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  width: 300px;
  height: 235px;
  position: absolute;
  bottom: -235px;
  background: #fff;
  box-shadow: 2px 4px 2px 2px rgba( 0, 0, 0, 0.4 );
  color: #000;
}

.fp-cal-container table, th, tr, td {
  border: 0;
}
.fp-cal-container table {
  font-size: inherit;
  width: 100%;
  padding: 0;
}
.fp-cal-nav {
  padding: 10px 10px 10px 10px;
  text-align: center;
}
.fp-cal-right {
  float: right;
  cursor: pointer;
}
.fp-cal-left {
  float: left;
  cursor: pointer;
}
.fp-cal-day-label {
  width: 14%;
}
.fp-cal-date {
  vertical-align: middle;
  height: 30px;
  cursor: pointer;
  text-align: center;
}
.fp-cal-date.active {
  border-radius: 4px;
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #006dcc;
  background-image: -moz-linear-gradient(top, #0088cc, #0044cc);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#0088cc), to(#0044cc));
  background-image: -webkit-linear-gradient(top, #0088cc, #0044cc);
  background-image: -o-linear-gradient(top, #0088cc, #0044cc);
  background-image: linear-gradient(to bottom, #0088cc, #0044cc);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0088cc', endColorstr='#ff0044cc', GradientType=0);
  border-color: #0044cc #0044cc #002a80;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #0044cc;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */

  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}

.fp-cal-date.fp-disabled {
  color: #a0a0a0;
  cursor: default;
}
