﻿@import (reference) "../lib/bootstrap-variables.less";
@import (reference) "../lib/font-awesome-variables.less";

/* --------------------------------------------------------
 * Bootstrap Overrides
 * --------------------------------------------------------
 */
a, a:hover, a:focus { text-decoration: none; }
body {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.6;
  color: #fff;
  background-color: #000;
}
.container.dark label { font-weight: 300; color: #a2a2a2; }
p { text-align: center; }
@media (min-width: 768px) {
    p {
        text-align: left;
    }
}
fieldset { border-bottom: 1px solid #666; margin-bottom: 30px; padding-bottom: 30px;}
fieldset.dark { background-color: rgba(0,0,0,0.6); padding: 40px; }

.inline {
    display: inline;
}

.normal {
    font-weight: normal;
}

.large {
    font-size: 1.2em;
}

.alert {
    a {
        text-decoration: underline;
    }
}

kbd {
    background-color: #777;

    kbd + kbd:before {
        content: ' + ';
        color: #ABA2A2;
    }
}

.thumbnail {
    position: relative;

    h1, h2, h3, h4, h5, h6 {
        margin: 0;
    }

    h4, h5, h6 {
        line-height: 30px;
    }

    .no-image {
        color: #333;
        text-align: center;
        line-height: 180px;
        height: 180px;
        background: #eee;
    }

    & > img, & > a {
        background: #eee;
        padding: 10px 0;
        display: block;
    }
    & > img, & a > img {
        width: 100%;
        height: auto;

        &.img-original-size {
            max-width: 100%;
        }
    }

    &.loading {
        background-image: none;

        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 86px;
            left: 50%;
            width: 16px;
            height: 16px;
            margin-left: -8px;
            background-image: url(../../images/gui/loaders/ajax-loader-small-blackonwhite.gif);
        }
    }

    .prev, .next {
        position: absolute;
        top: 70px;
        font-size: 2em;
        border: none;
        background: none;
        outline: none;

        opacity: 0.5;

        -moz-transition: opacity 100ms, text-shadow 100ms;
        -o-transition: opacity 100ms, text-shadow 100ms;
        -webkit-transition: opacity 100ms, text-shadow 100ms;
        transition: opacity 100ms, text-shadow 100ms;
        
        text-shadow: 0 3px #aaa;
        text-shadow: 0 3px rgba(0,0,0,0.1);
    }

    .next {
        right: 4px;
    }

    &:hover {
        .prev, .next {
            opacity: 1;
            text-shadow: 0 3px #666;
            text-shadow: 0 3px rgba(0,0,0,0.5);
        }
    }
}

abbr, abbr[title] {
    font-size: 0.8em;
    color: #333;
    border-bottom: none;

    cursor: default;
    cursor: inherit;
    
    text-transform: uppercase;
    margin-left: 2px;
}

.label + .label {
    margin-left: 5px;
}

.tooltip-inner {
    -moz-user-select: text;
    -ms-user-select: text;
    -webkit-user-select: text;
    user-select: text;
}

@media (min-width: 768px) {
    dl.large {
        @term-width: 225px;
        dt {
            width: @term-width;
            line-height: 2;
        }

        dd {
            margin: 10px 0 10px @term-width + 20px;
            font-size: 1.3em;
        }
    }
}

.breadcrumb > span {
    color: #333;
    font-weight: bold;
    vertical-align: middle;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: "proxima-nova-condensed", Helvetica, Arial, sans-serif;
  font-weight: 300;
  line-height: 1.1;
}
h1, h2, h3, h4 { 
    margin-bottom: 20px;
    text-align: center;

    &.underlined {
        border-bottom: 1px solid;
    }
}

@media (min-width: 768px) {
    h1, h2, h3, h4 { text-align: left; }
}

h1, .h1 { font-size: 48px; }
h2, .h2 { font-size: 36px; }
h3, .h3 { font-size: 30px; }
h4, .h4 { font-size: 24px; }
h5, .h5 { font-size: 18px; }
h6, .h6 { font-size: 14px; }

h4, h5, h6 {
    margin-top: 20px;
}

.panel.collapsing.with-dropdown {
    overflow: hidden;
}

.panel-heading {
    h4, .h4, h5, .h5 {
        margin: 5px 0;
    }
}

.panel-body {
    position: relative;
}

.show-grid [class^="col-"] {
  padding-top: 20px;
  padding-bottom: 20px;
}
@media (min-width: 768px) {
    .show-grid [class^="col-"] {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}
legend { color: #fff; }

.well.highlight { background-color: #d72426; }
.well.selected { border: 1px solid #47a447; }
.well .bar {
    background-color: #222;
    padding: 10px 19px;
    margin: 20px -19px;
}
.well .bar.loading {height: 60px;}

.glyphicon { vertical-align: text-top; }

.navbar {
    >.container {
        margin: 10px auto 20px auto !important;
    }

    &.compact {
        >.container {
            margin: 0 auto 5px auto !important;
        }
    }

    .navbar-brand {
        margin-top: -5px;
    }

    .nav .dropdown-menu {
        max-height: none;
    }

    .nav button + ul.dropdown-menu {
        margin-top: 0 !important;
    }
}

/* --------------------------------------------------------
 * Sitewide styles
 * --------------------------------------------------------
 */
main > article:first-child {
    padding-top: 90px;

    .banner-visible & {
        padding-top: 122px;
    }
}
article { background-color: #2d2d2d; padding: 30px 0; }
article.black { background-color: #000; }
article.dark { background-color: #131313; }
article.light { background-color: #647379; color: #FFF; }
article.highlight { background-color: #B3D5DF; color: #647379 }
.texture { background-image: url(../../images/gui/bg/wall.png); }
article.flush-top { padding-top: 0; }

@media (min-width: 992px) {
    article.flush-bottom-md {
        padding-bottom: 0;
    }
}
article fieldset legend { border-bottom: none; font-weight: 400; }
article fieldset legend span {
    border: 3px solid #1cf9e3;
    border-radius: 24px;
    color: #1cf9e3;
    display: inline-block;
    margin-right: 15px;
    padding-left: 10px;
    padding-right: 10px;
}
.panel, .well {
    background-color: rgba(0,0,0,0.2);
    background-image: none !important;
}
article .well { border: none; }
article .container.dark { background-color: rgba(0,0,0,0.2); }
article.fill {
    position: absolute;
    bottom: 0;
    left:0;
    right: 0;
    top: 0;
}

.bg-top-right { background-position: left top; }

@media (min-width: 992px) {
    .bg-ipad-hand { background-image: url(../images/share-ipad-hand.png); background-repeat: no-repeat; height: 507px; }
    .bg-top-right { background-position: right top; }
}

.jumbotron { margin-bottom: 0; }
a.contrast { color: #fff; }
a.contrast:hover { color: #00fffe; text-decoration: none; }

.dockable { position: relative; }
.dock { position: absolute; }
.dock-top { top: 0; }
.dock-bottom { right: 0px; bottom: 0px; left: 0px; }
.dock-left { left: 0; }
.dock-right { right: 0; }

.fill-block { width: 100%; }

@media (max-width: 768px) {
    img.compact { margin-left: auto; margin-right: auto; max-width: 180px; height: auto; }
}

img.img-original-size {
    width: auto !important;
    height: auto !important;
}
img.img-center {
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 767px) {
    .list-centered { text-align: center;  width: 100%; }
}

@media (min-width: 768px) {
    .navbar.compact { background-color: #000; }
    .navbar.compact > .container {
        margin: 10px auto;
        transition: margin 250ms;
        -webkit-transition: margin 250ms;
    }
}

.progress-floating {
    position: absolute;
    
    left: 50%;
    top: 50%;
    
    z-index: 10;

    @width: 300px;
    @height: 20px;
    
    width: @width;
    height: @height;

    margin-top: -@height/2;
    margin-left: -@width/2;
}

.progress-large {
    @height: 30px;

    height: @height;
    border-radius: 5px;

    .progress-bar {
        line-height: @height;
        font-size: 14px;
    }

    &.progress-floating {
        margin-top: -@height/2;
    }
}

.progress-striped .progress-bar,
.progress-bar-striped {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  background-image:      -o-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  background-image:         linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  -webkit-background-size: 40px 40px;
          background-size: 40px 40px;
}

th {
    padding: 0 8px 5px;
}

table.table.compact th { font-size: 12px; }
.table-striped > tbody > tr:nth-child(odd) > {
    td, th {
        background-color: #404040;
    }
}
.table > tbody > tr > td {
    vertical-align: middle;
}

.table.no-borders {
    td {
        border: none;
    }
}

.translucent {
  background-color: rgba(0,0,0,0.9);
  background-image: none;
}
@media (min-width: 768px) {
    .translucent {
      background-color: rgba(0,0,0,0.6);
    }
}
.with-room { padding: 30px; }
.with-room-wide { padding-left: 30px; padding-right: 30px; }
.with-room-tall { padding-top: 30px; padding-bottom: 30px; }

.with-space { margin: 30px; }
.with-space-bottom { margin-bottom: 30px; }
.with-space-wide { margin-left: 30px; margin-right: 30px; }
.with-space-tall { margin-top: 30px; margin-bottom: 30px; }
.with-space-top { margin-top: 30px; }

.with-space-top-4 { margin-top: 90px; }

/* --------------------------------------------------------
 * Navbar styles
 * --------------------------------------------------------
 */

.navbar-header {
    position: relative;
}
.navbar-header .tagline {
    font-size: 14px;
    left: 210px;
    position: absolute;
    top: 15px;
    width: 240px;
}
.navbar-collapse.in {
    max-height: 410px;
}
.navbar-inverse .navbar-nav > li > a {
    color: #ccc;
    background-color: transparent;
}
.navbar-inverse .navbar-nav>.active > a:active { color: #fff; }
.navbar-inverse .navbar-nav>.active > a,
.navbar-inverse .navbar-nav>.active > a:hover,
.navbar-inverse .navbar-nav>.active > a:focus {
    background: none;
}
.navbar-inverse .navbar-nav > li > a.btn { 
    color: #fff;
    margin: 4px 20px;
    padding-top: 10px; 
    padding-bottom: 10px; }
.navbar-inverse .navbar-nav>li>a.btn-danger:hover {
    background-color: #d2322d;
}
.nav > li > a {
    padding-left: 20px;
    padding-right: 20px;
    background-color: #444;
    color: #ccc;
}
.nav>li>a:hover, .nav>li>a:focus {
    color: #222;
}
.nav.open .btn.dropdown-toggle {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

@media (min-width: 768px) {
    ul.nav.navbar-nav {
        float: right;
    }

    .navbar .nav {
        height: 50px;
        line-height: 49px;
        margin: 0 10px;
        position: relative;
    }

    .navbar .nav button + ul.dropdown-menu {
        margin-top: -8px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        min-width: 100%;
    }

    .navbar-inverse .navbar-nav > li > a.btn {
        margin: 4px 0 4px 20px;
    }
    .navbar-inverse .container {
        margin: 20px auto; transition: margin 250ms; -webkit-transition: margin 250ms;
    }
}

@media (min-width: 992px) {
    .navbar-inverse .container {
        margin: 32px auto; transition: margin 250ms; -webkit-transition: margin 250ms;
    }
}

/* --------------------------------------------------------
 * Content styles
 * --------------------------------------------------------
 */

.marquee.jumbotron {
    background-color: #000;
    background-image: url(../images/marquee-xs.jpg);
    background-position: center top;
    background-repeat: no-repeat;
    /*background-size: cover;*/
    height: 420px;
}
.marquee-action {
    margin-top: 270px;
}

/*@media (min-width: 420px) {
    
 }*/

 @media (min-width: 768px) {
    .marquee-action {
        margin-top: 126px;
    }
    .marquee.jumbotron {
        background-image: url(../images/marquee-sm.jpg);
        background-size: auto;
        height: 570px;
    }
 } 

@media (min-width: 1200px) {
    .marquee-action {
        margin-top: 200px;
    }
    .marquee.jumbotron {
        background-image: url(../images/marquee.jpg);
        background-size: auto;
        height: 698px;
    }
}
 /*No RGBA workarounds*/

.no-rgba fieldset.dark,
.no-rgba article .panel, 
.no-rgba article .well,
.no-rgba article .container.dark,
.no-rgba .translucent { 
    background-color: #1a1a1a; 
    background-image: none;
}
ul.dropdown-menu {
    max-height: 200px;
    overflow-x: hidden;
    overflow-y: auto;

    li {
        color: #222;
    }

    li a {
        &:hover {
            color: #fff;
        }

        & >.fa {
            font-size: 2em;
            vertical-align: middle;
            width: 1em;
            margin-right: 10px;
            text-align: center;
        }
    }
}
.dropdown-menu-full {
    right: 0;
    left: 0;
}
.dropdown-menu-center {
    left: 50%;
    width: 250px;
    margin-left: -125px;
    text-align: center;
}

.navbar-inverse {
    ul.dropdown-menu {
        background: #000;
        li a {
            color: #fff;
            &:hover {
                background: #fff;
                color: #000;
            }
        }
    }
}

.input-inline {
    display: inline;
}

table {
    h4 {
        margin-bottom: 0;
    }

    tr {
        &.active {
            td {
                color: #000;
            }
        }

        &.success {
            td {
                color: #000;
            }
        }
    }
}

.control-label {
    font-weight: bold;
}

.panel {
    border-radius: 5px;
    overflow: hidden;

    &.form-box {
        max-width: 600px;
        margin: auto;

        h1 {
            font-size: 32px;
            margin-top: 10px;
        }
    }

    &.with-dropdown {
        overflow: visible;
    }

    .panel-heading {
        border-radius: 0;
        padding: 10px 0;
        margin: 0 15px -1px 15px;

        img {
            height: auto;
            max-width: 100%;
        }
    }

    .panel-footer {
        border-radius: 0;
        padding: 10px;

        background-color: rgba(255,255,255,0.1);
        background-color: rgba(58, 58, 58, 1);
    }

    &.panel-primary {
        border: 1px solid #111;
        color: #efefef;

        .panel-heading {
            background: transparent;
            border-bottom: 1px solid #efefef;
        }

        .panel-footer {
            border-top: 1px solid #efefef;
        }
    }
}

iframe {
    margin: 0;
    padding: 0;
    border: none;
}

.modal-backdrop {
    z-index: 1050;

    &.in {
        background-color: #fff;
    }
}

.popover {
    min-width: 155px;
    background: black;
}

.popover-title {
    background-color: #242424;
    font-size: 16px;
}

.popover-content {
    background-color: #333;
}

.popover > .arrow {
    border-bottom-color: red;
}

.popover.bottom > .arrow:after {
    border-bottom-color: black;
}

.modal {
    abbr {
        color: #bbb;
    }

    &.modal-full {
        .modal-dialog {
            width: auto; 
            position: absolute;
            top: 2%; bottom: 2%; left: 2%; right: 2%;
            margin: 0;
        }

        .modal-content {
            width: 100%;
            height: 100%;
        }

        .modal-body {
            height:  ~"calc(100% - 40px)";
        }

        iframe {
            width: 100%;
            height: 100%;
        }
    }

    .modal-content {
        background: #333;
        padding: 0 15px;

        .modal-header, .modal-body, .modal-footer {
            padding: 15px 0;

            h4:first-child {
                margin-top: 0;
            }
        }
        
        .modal-header-empty {
            border-bottom:none;
            padding: 10px 0;
        }

        .modal-overlay {
            position: absolute;
            left: 0;
            right: 0;
            background-color: #333;
            background-color: rgba(50,50,50,0.9);
            z-index: 10;
            padding: 15px;
            box-shadow: 0 5px 8px rgba(0,0,0,.5);

            &.full {
                top: 0; bottom: 0;
                box-shadow: none;
            }
        }

        .modal-id {
            & , a {
                color: #666;
            }
            
            position: absolute;
            bottom: 5px;
            right: 15px;
            font-size: 10px;

            .tooltip-inner {
                max-height: 150px;
                max-width: none;
                width: 280px;
                
                overflow-x: hidden;
                overflow-y: auto;

                white-space: nowrap;
            }
        }

        .modal-header {
            h4 {
                margin: 0;
            }

            .input-group {
                margin-right: 20px;
            }
        }

        .modal-body {
            &.scrollable {
                max-height: 500px;
                overflow: auto;
                overflow-x: hidden;
                -webkit-overflow-scrolling: touch;
                margin: 5px -13px 5px -16px;
                padding: 15px 13px 15px 16px;
            }

            &.loading {
                background-position: center;
            }

            .tableFloatingHeaderOriginal {
                left: 16px !important;
            }

            .popover {
                color: #333;
            }
        }

        &.with-id {
            padding-bottom: 25px;
        }

        .modal-footer.with-id {
            padding-bottom: 25px;
        }

        .close {
            color: #efefef;
            text-shadow: 0 1px 0 #333;

            &:hover {
                color: #fff;
            }
        }

        .tab-pane {
            padding-top: 30px;
            position: relative;
        }

        .footer-error {
            color: @brand-danger;

            &:before {
                content: @fa-var-exclamation-triangle;
                margin-right: 10px;
                font-family: FontAwesome;
                vertical-align: middle;
            }
        }
    }

    .data-table-loading > .loading-indicator {
        position: absolute;
        margin-left: -80px;
    }

    &.loading {
        background-image: none;
        position: fixed;

        .modal-header, .modal-body, .modal-footer {
            display: none !important;
        }

        .modal-content {
            background-image: url(../../images/gui/loaders/preloader-light-160x20.gif);
            background-position: center center;
            background-repeat: no-repeat;
            min-height: 100px;
        }
    }

    &.fade:not(.in) {
        &.left .modal-dialog {
            -webkit-transform: translate3d(-25%, 0, 0);
	        transform: translate3d(-25%, 0, 0);
        }
        &.right .modal-dialog {
	        -webkit-transform: translate3d(125%, 0, 0);
	        transform: translate3d(125%, 0, 0);
        }
        &.bottom .modal-dialog {
            -webkit-transform: translate3d(0, 125%, 0);
	        transform: translate3d(0, 125%, 0);
        }
        &.minimized .modal-dialog {
            -webkit-transform: translate3d(0, 125%, 0);
	        transform: translate3d(0, 125%, 0);
        }
    }

    #viewport & {
        z-index: 1051;
    }
}

.row {
    clear: both;
}

.badge {
    &.badge-primary { background-color:  @brand-primary; }
    &.badge-success { background-color:  @brand-success; }
    &.badge-info { background-color:  @brand-info; }
    &.badge-warning { background-color:  @brand-warning; }
    &.badge-danger { background-color:  @brand-danger; }
}

.input-group {
    .btn {
        font-size: 14px;
    }
}

.field-group-header {
    margin-bottom: 5px;
}

.form-group:last-child {
    margin-bottom: 0;
}

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
    background-color: #aaa;
    color: #333;
}

.has-error .form-control {
    border-width: 2px;
    border-style: solid;
    color: #a94442;
}

.label, abbr.label {
    padding: 0.2em 0.4em;
    color: white;
}
.label.large {
    font-size: 100%;
    padding: 0.4em 0.6em;
    font-weight: normal;
}
.label-block {
    display: block;
}

.list-group-item {
    color: #000;
}

.ui-sortable {
    li {
        cursor: move;

        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
}
.text-success {
    color: @brand-success;
}
.text-warning {
    color: @brand-warning;
}

h1, h2, h3, h4, h5, h6 {
    & .text-warning {.text-warning();}
}

.btn-tiny, .btn-group-tiny>.btn {
    padding: 3px 7px;
    font-size: 10px;
    line-height: 1em;
    border-radius: 3px;
}

.dropdown-menu-item(@color) {
    color: white;

    @light: lighten(@color, 2%);

    background-color: @color;
    background-image: -webkit-gradient(linear, left 0%, left 100%, from(@light), to(@color));
    background-image: -webkit-linear-gradient(top, @light, 0%, @color, 100%);
    background-image: -moz-linear-gradient(top, @light 0%, @color 100%);
    background-image: linear-gradient(to bottom, @light 0%, @color 100%);
    background-repeat: repeat-x;

    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='@{light}', endColorstr='@{color}', GradientType=0);
}

.dropdown-menu > li > a.dropdown-menu-item-danger {
    .dropdown-menu-item(@brand-danger);

    &:hover, &:focus {
        .dropdown-menu-item(darken(@brand-danger, 10%))
    }
}